import layoutHeaderAside from '@/layout/header-aside'

// 由于懒加载页面太多的话会造成webpack热更新太慢，所以开发环境不使用懒加载，只有生产环境使用懒加载
const _import = require('@/libs/util.import.' + process.env.NODE_ENV)

/**
 * 在主框架内显示
 */
const frameIn = [
  {
    path: '/',
    redirect: { name: 'index' },
    component: layoutHeaderAside,
    children: [
      // 首页
      {
        path: '/index',
        name: 'index',
        meta: {
          auth: true,
          cache: true
        },
        component: _import('system/index')
      },
      // 系统 前端日志
      {
        path: '/log',
        name: 'log',
        meta: {
          title: '前端日志',
          auth: true
        },
        component: _import('system/log')
      },
      // 系统 前端日志
      {
        path: '/info',
        name: 'info',
        meta: {
          title: '个人信息',
          auth: true
        },
        component: _import('admin/info')
      },
      {
        path: '/401',
        name: '401',
        hidden: true,
        component: _import('system/error/401')
      },
      // 刷新页面 必须保留
      {
        path: '/refresh',
        name: 'refresh',
        hidden: true,
        component: _import('system/function/refresh')
      },
      // 页面重定向 必须保留
      {
        path: '/redirect/:route*',
        name: 'redirect',
        hidden: true,
        component: _import('system/function/redirect')
      }
    ]
  }
]

/**
 * 在主框架之外显示
 */
const frameOut = [
  // 登录
  {
    path: '/login',
    name: 'login',
    component: _import('system/login')
  }
]

/**
 * 临时新增修改配合界面
 */
const addEdit = [
  {
    path: '/operation',
    component: layoutHeaderAside,
    children: [
      {
        path: '/operation/advorder/edit/index',
        name: 'advorder_edit',
        component: _import(`operation/advorder/edit/index.vue`),
        meta: {
          auth: true,
          cache: true,
          title: '编辑预订单'
        }
      }
    ]
  },
  {
    path: '/operation',
    component: layoutHeaderAside,
    children: [
      {
        path: '/operation/supply/edit/index',
        name: 'supply_edit',
        component: _import(`operation/supply/edit/index.vue`),
        meta: {
          auth: true,
          cache: true,
          title: '编辑货源单'
        }
      }
    ]
  },
  {
    path: '/operation',
    component: layoutHeaderAside,
    children: [
      {
        path: '/operation/waybill/edit/index',
        name: 'waybill_edit',
        component: _import(`operation/waybill/edit/index.vue`),
        meta: {
          auth: true,
          cache: true,
          title: '编辑托运单'
        }
      }
    ]
  },
  {
    path: '/operation',
    component: layoutHeaderAside,
    children: [
      {
        path: '/operation/waybill/add/index',
        name: 'waybill_add',
        component: _import(`operation/waybill/add/index.vue`),
        meta: {
          auth: true,
          cache: true,
          title: '新增托运单'
        }
      }
    ]
  },
  {
    path: '/operation',
    component: layoutHeaderAside,
    children: [
      {
        path: '/operation/shortbill/edit/index',
        name: 'shortbill_edit',
        component: _import(`operation/shortbill/edit/index.vue`),
        meta: {
          auth: true,
          cache: true,
          title: '编辑短驳单'
        }
      }
    ]
  },
  {
    path: '/operation',
    component: layoutHeaderAside,
    children: [
      {
        path: '/operation/delivery/edit/index',
        name: 'delivery_edit',
        component: _import(`operation/delivery/edit/index.vue`),
        meta: {
          auth: true,
          cache: true,
          title: '编辑送货单'
        }
      }
    ]
  },
  {
    path: '/operation',
    component: layoutHeaderAside,
    children: [
      {
        path: '/operation/transfer/edit/index',
        name: 'transfer_edit',
        component: _import(`operation/transfer/edit/index.vue`),
        meta: {
          auth: true,
          cache: true,
          title: '编辑中转单'
        }
      }
    ]
  },
  {
    path: '/operation',
    component: layoutHeaderAside,
    children: [
      {
        path: '/operation/transfer/insureRecord/insureRecord',
        name: 'insureRecord',
        component: _import(`operation/transfer/insureRecord/insureRecord.vue`),
        meta: {
          auth: true,
          cache: false,
          title: '中转单投保记录'
        }
      }
    ]
  },
  {
    path: '/operation',
    component: layoutHeaderAside,
    children: [
      {
        path: '/operation/transaction/edit/index',
        name: 'transaction_edit',
        component: _import(`operation/transaction/edit/index.vue`),
        meta: {
          auth: true,
          cache: true,
          title: '编辑异动单'
        }
      }
    ]
  },
  {
    path: '/wealth',
    component: layoutHeaderAside,
    children: [
      {
        path: '/wealth/diversity/edit/index',
        name: 'diversity_edit',
        component: _import(`wealth/diversity/edit/index.vue`),
        meta: {
          auth: true,
          cache: true,
          title: '对账差异管理'
        }
      }
    ]
  },
  {
    path: '/wealth',
    component: layoutHeaderAside,
    children: [
      {
        path: '/wealth/salesinvoice/edit/index',
        name: 'salesinvoice_edit',
        component: _import(`wealth/salesinvoice/edit/index.vue`),
        meta: {
          auth: true,
          cache: true,
          title: '编辑销项票'
        }
      }
    ]
  },
  {
    path: '/wealth',
    component: layoutHeaderAside,
    children: [
      {
        path: '/wealth/inputinvoice/edit/index',
        name: 'inputinvoice_edit',
        component: _import(`wealth/inputinvoice/edit/index.vue`),
        meta: {
          auth: true,
          cache: true,
          title: '编辑进项票'
        }
      }
    ]
  },
  {
    path: '/wealth',
    component: layoutHeaderAside,
    children: [
      {
        path: '/wealth/baddebt/edit/index',
        name: 'baddebt_edit',
        component: _import(`wealth/baddebt/edit/index.vue`),
        meta: {
          auth: true,
          cache: true,
          title: '编辑坏账单'
        }
      }
    ]
  },
  {
    path: '/finance',
    component: layoutHeaderAside,
    children: [
      {
        path: '/finance/repaymanage/edit/index',
        name: 'repaymanage_edit',
        component: _import(`finance/repaymanage/edit/index.vue`),
        meta: {
          auth: true,
          cache: true,
          title: '编辑请款单'
        }
      }
    ]
  },
  {
    path: '/hr',
    component: layoutHeaderAside,
    children: [
      {
        path: '/hr/defrayment/edit/index',
        name: 'defrayment_edit',
        component: _import(`hr/defrayment/edit/index.vue`),
        meta: {
          auth: true,
          cache: true,
          title: '编辑成本单'
        }
      }
    ]
  },
  {
    path: '/finance',
    component: layoutHeaderAside,
    children: [
      {
        path: '/finance/receiptpayment/edit/index',
        name: 'receiptpayment_edit',
        component: _import(`finance/receiptpayment/edit/index.vue`),
        meta: {
          auth: true,
          cache: true,
          title: '编辑收付款'
        }
      }
    ]
  },
  {
    path: '/operation',
    component: layoutHeaderAside,
    children: [
      {
        path: '/operation/waybill/print/index',
        name: 'waybill_print',
        component: _import(`operation/waybill/print/index.vue`),
        meta: {
          auth: true,
          cache: true,
          title: '打印托运单'
        }
      }
    ]
  },
  {
    path: '/reportformOperate',
    component: layoutHeaderAside,
    children: [
      {
        path: '/reportformOperate/waybillgros/detail',
        name: 'waybillgros_detail',
        component: _import(`reportformOperate/waybillgros/detail.vue`),
        meta: {
          auth: true,
          cache: true,
          title: '单票运单毛利分析'
        }
      }
    ]
  },
  {
    path: '/finance',
    component: layoutHeaderAside,
    children: [
      {
        path: '/finance/bankmanage/dealHistory/index',
        name: 'deal_history',
        component: _import(`finance/bankmanage/dealHistory/index.vue`),
        meta: {
          auth: true,
          cache: true,
          title: '交易历史'
        }
      }
    ]
  },
  {
    path: '/market',
    component: layoutHeaderAside,
    children: [
      {
        path: '/market/quotation/edit/index',
        name: 'quotation_edit',
        component: _import(`market/quotation/edit/index.vue`),
        meta: {
          auth: true,
          cache: true,
          title: '编辑损益表'
        }
      }
    ]
  },
  {
    path: '/market',
    component: layoutHeaderAside,
    children: [
      {
        path: '/market/offerquotation/edit/index',
        name: 'offerquotation_edit',
        component: _import(`market/offerquotation/edit/index.vue`),
        meta: {
          auth: true,
          cache: true,
          title: '编辑报价损益'
        }
      }
    ]
  },
  {
    path: '/market',
    component: layoutHeaderAside,
    children: [
      {
        path: '/market/forwarding/forwardingadd/index',
        name: 'forwardingadd',
        component: _import(`market/forwarding/forwardingadd/index.vue`),
        meta: {
          auth: true,
          cache: true,
          title: '新增项目管理'
        }
      }
    ]
  },
  {
    path: '/market',
    component: layoutHeaderAside,
    children: [
      {
        path: '/market/forwarding/forwardingedit/index',
        name: 'forwardingedit',
        component: _import(`market/forwarding/forwardingedit/index.vue`),
        meta: {
          auth: true,
          cache: true,
          title: '项目管理详情'
        }
      }
    ]
  },
  {
    path: '/basic',
    component: layoutHeaderAside,
    children: [
      {
        path: '/basic/carrier/carrierAdd/index',
        name: 'carrieradd',
        component: _import(`basic/carrier/carrierAdd/index.vue`),
        meta: {
          auth: true,
          cache: true,
          title: '新增承运单位'
        }
      }
    ]
  },
  {
    path: '/basic',
    component: layoutHeaderAside,
    children: [
      {
        path: '/basic/carrier/carrierEdit/index',
        name: 'carrieredit',
        component: _import(`basic/carrier/carrierEdit/index.vue`),
        meta: {
          auth: true,
          cache: true,
          title: '承运单位详情'
        }
      }
    ]
  },
  {
    path: '/market',
    component: layoutHeaderAside,
    children: [
      {
        path: '/market/forwarding/forwardDealHistory/index',
        name: 'forwardDealHistory',
        component: _import(`market/forwarding/forwardDealHistory/index.vue`),
        meta: {
          auth: true,
          cache: true,
          title: '交易历史'
        }
      }
    ]
  },
  {
    path: '/finance',
    component: layoutHeaderAside,
    children: [
      {
        path: '/finance/partnerbank/partnerHistory/index',
        name: 'partnerHistory',
        component: _import(`finance/partnerbank/partnerHistory/index.vue`),
        meta: {
          auth: true,
          cache: true,
          title: '伙伴交易历史'
        }
      }
    ]
  },
  {
    path: '/operation',
    component: layoutHeaderAside,
    children: [
      {
        path: '/operation/insurance/edit/index',
        name: 'insurance_edit',
        component: _import(`operation/insurance/edit/index.vue`),
        meta: {
          auth: true,
          cache: true,
          title: '编辑保险单'
        }
      }
    ]
  },
  {
    path: '/finance',
    component: layoutHeaderAside,
    children: [
      {
        path: '/finance/partneraccount/edit/index',
        name: 'partneraccount_edit',
        component: _import(`finance/partneraccount/edit/index.vue`),
        meta: {
          auth: true,
          cache: true,
          title: '编辑伙伴流水'
        }
      }
    ]
  },
  {
    path: '/budget',
    component: layoutHeaderAside,
    children: [
      {
        path: '/budget/incomegoal/edit/index',
        name: 'incomegoal_edit',
        component: _import(`budget/incomegoal/edit/index.vue`),
        meta: {
          auth: true,
          cache: true,
          title: '编辑月度预算报表'
        }
      }
    ]
  },
  {
    path: '/operation',
    component: layoutHeaderAside,
    children: [
      {
        path: '/operation/enquiry/edit/index',
        name: 'enquiry_edit',
        component: _import(`operation/enquiry/edit/index.vue`),
        meta: {
          auth: true,
          cache: true,
          title: '编辑询价单'
        }
      }
    ]
  },
  {
    path: '/operation',
    component: layoutHeaderAside,
    children: [
      {
        path: '/operation/enquiry/priceHistory/index',
        name: 'priceHistory',
        component: _import(`operation/enquiry/priceHistory/index.vue`),
        meta: {
          auth: true,
          cache: true,
          title: '报价历史'
        }
      }
    ]
  },
  {
    path: '/operation',
    component: layoutHeaderAside,
    children: [
      {
        path: '/operation/enquiry/priceResult/index',
        name: 'priceResult',
        component: _import(`operation/enquiry/priceResult/index.vue`),
        meta: {
          auth: true,
          cache: true,
          title: '询价结果'
        }
      }
    ]
  },
  {
    path: '/finance',
    component: layoutHeaderAside,
    children: [
      {
        path: '/finance/ledger/edit/index',
        name: 'ledger_edit',
        component: _import(`finance/ledger/edit/index.vue`),
        meta: {
          auth: true,
          cache: true,
          title: '编辑台账单'
        }
      }
    ]
  },
  {
    path: '/finance',
    component: layoutHeaderAside,
    children: [
      {
        path: '/finance/receiptpayment/secondtable/index',
        name: 'secondTable',
        component: _import(`finance/receiptpayment/secondtable/index.vue`),
        meta: {
          auth: true,
          cache: true,
          title: '收付款二级表'
        }
      }
    ]
  },
  {
    path: '/finance',
    component: layoutHeaderAside,
    children: [
      {
        path: '/finance/receiptpayment/thirdtable/index',
        name: 'thirdTable',
        component: _import(`finance/receiptpayment/thirdtable/index.vue`),
        meta: {
          auth: true,
          cache: true,
          title: '收付款三级表'
        }
      }
    ]
  },
  {
    path: '/inventory',
    component: layoutHeaderAside,
    children: [
      {
        path: '/inventory/purchaseRequisitions/edit/index',
        name: 'purchase_edit',
        component: _import(`inventory/purchaseRequisitions/edit/index.vue`),
        meta: {
          auth: true,
          cache: true,
          title: '编辑采购单'
        }
      }
    ]
  },
  {
    path: '/inventory',
    component: layoutHeaderAside,
    children: [
      {
        path: '/inventory/itemsRecipients/edit/index',
        name: 'itemsRecipient_edit',
        component: _import(`inventory/itemsRecipients/edit/index.vue`),
        meta: {
          auth: true,
          cache: true,
          title: '编辑领用单'
        }
      }
    ]
  },
  {
    path: '/customer',
    component: layoutHeaderAside,
    children: [
      {
        path: '/customer/accident/accidentadd/index',
        name: 'accident_add',
        component: _import(`customer/accident/accidentadd/index.vue`),
        meta: {
          auth: true,
          cache: true,
          title: '事故登记'
        }
      }
    ]
  },
  {
    path: '/customer',
    component: layoutHeaderAside,
    children: [
      {
        path: '/customer/accident/accidentedit/index',
        name: 'accident_edit',
        component: _import(`customer/accident/accidentedit/index.vue`),
        meta: {
          auth: true,
          cache: true,
          title: '事故处理'
        }
      }
    ]
  },
  {
    path: '/market',
    component: layoutHeaderAside,
    children: [
      {
        path: '/market/introduce/introduceEdit/index',
        name: 'introduceEdit',
        component: _import(`market/introduce/introduceEdit/index.vue`),
        meta: {
          auth: true,
          cache: true,
          title: '编辑立项报告'
        }
      }
    ]
  },
  {
    path: '/market',
    component: layoutHeaderAside,
    children: [
      {
        path: '/market/passenger/passengerEdit/index',
        name: 'passengerEdit',
        component: _import(`market/passenger/passengerEdit/index.vue`),
        meta: {
          auth: true,
          cache: true,
          title: '编辑客户漏斗'
        }
      }
    ]
  },
  {
    path: '/budget',
    component: layoutHeaderAside,
    children: [
      {
        path: '/budget/earlygoal/edit/index',
        name: 'earlygoal_edit',
        component: _import(`budget/earlygoal/edit/index.vue`),
        meta: {
          auth: true,
          cache: true,
          title: '编辑年初目标'
        }
      }
    ]
  },
  {
    path: '/hr',
    component: layoutHeaderAside,
    children: [
      {
        path: '/hr/paySMS/edit/index',
        name: 'paySMS_edit',
        component: _import(`hr/paySMS/edit/index.vue`),
        meta: {
          auth: true,
          cache: true,
          title: '发送工资短信'
        }
      }
    ]
  },
  {
    path: '/market',
    component: layoutHeaderAside,
    children: [
      {
        path: '/market/performancebond/edit/index',
        name: 'performanceEdit',
        component: _import(`market/performancebond/edit/index.vue`),
        meta: {
          auth: true,
          cache: true,
          title: '编辑履约保证金'
        }
      }
    ]
  },
  {
    path: '/market',
    component: layoutHeaderAside,
    children: [
      {
        path: '/market/tenderbond/edit/index',
        name: 'tenderbondEdit',
        component: _import(`market/tenderbond/edit/index.vue`),
        meta: {
          auth: true,
          cache: true,
          title: '编辑投标保证金'
        }
      }
    ]
  },
  {
    path: '/market',
    component: layoutHeaderAside,
    children: [
      {
        path: '/market/contractaccount/edit/index',
        name: 'contractaccountEdit',
        component: _import(`market/contractaccount/edit/index.vue`),
        meta: {
          auth: true,
          cache: true,
          title: '编辑合同台账'
        }
      }
    ]
  },
  {
    path: '/wealth',
    component: layoutHeaderAside,
    children: [
      {
        path: '/wealth/receivable/edit/index',
        name: 'receivable_edit',
        component: _import(`wealth/receivable/edit/index.vue`),
        meta: {
          auth: true,
          cache: true,
          title: '编辑应收账单'
        }
      }
    ]
  },
  {
    path: '/collectionMonth',
    component: layoutHeaderAside,
    children: [
      {
        path: '/budget/collectionMonth/edit/index',
        name: 'collectionMonth_edit',
        component: _import(`budget/collectionMonth/edit/index.vue`),
        meta: {
          auth: true,
          cache: true,
          title: '编辑月度回款计划'
        }
      }
    ]
  },
  {
    path: '/market',
    component: layoutHeaderAside,
    children: [
      {
        path: '/market/customerfreight/edit/index',
        name: 'customerfreight_edit',
        component: _import(`market/customerfreight/edit/index.vue`),
        meta: {
          auth: true,
          cache: true,
          title: '编辑客户运价'
        }
      }
    ]
  },
  {
    path: '/market',
    component: layoutHeaderAside,
    children: [
      {
        path: '/market/customerfreightrate/edit/index',
        name: 'customerFreightRate_edit',
        component: _import(`market/customerfreightrate/edit/index.vue`),
        meta: {
          auth: true,
          cache: true,
          title: '编辑客户运价'
        }
      }
    ]
  },
  {
    path: '/market',
    component: layoutHeaderAside,
    children: [
      {
        path: '/market/bondaccount/summary/index',
        name: 'bondaccount_summary',
        component: _import(`market/bondaccount/summary/index.vue`),
        meta: {
          auth: true,
          cache: true,
          title: '保证金汇总'
        }
      }
    ]
  },
  {
    path: '/fleet',
    component: layoutHeaderAside,
    children: [
      {
        path: '/fleet/vehiclemanagement/edit/index',
        name: 'vehiclemanagement_edit',
        component: _import(`fleet/vehiclemanagement/edit/index.vue`),
        meta: {
          auth: true,
          cache: true,
          title: '编辑车辆信息'
        }
      }
    ]
  },
  {
    path: '/fleet',
    component: layoutHeaderAside,
    children: [
      {
        path: '/fleet/vehiclemanagement/trailer/index',
        name: 'trailermanagement_edit',
        component: _import(`fleet/vehiclemanagement/trailer/index.vue`),
        meta: {
          auth: true,
          cache: true,
          title: '编辑挂车信息'
        }
      }
    ]
  },
  {
    path: '/fleet',
    component: layoutHeaderAside,
    children: [
      {
        path: '/fleet/costmanagement/edit/index',
        name: 'costmanagement_edit',
        component: _import(`fleet/costmanagement/edit/index.vue`),
        meta: {
          auth: true,
          cache: true,
          title: '编辑费用管理'
        }
      }
    ]
  },
  {
    path: '/fleet',
    component: layoutHeaderAside,
    children: [
      {
        path: '/fleet/driverFile/edit/index',
        name: 'driverFile_edit',
        component: _import(`fleet/driverFile/edit/index.vue`),
        meta: {
          auth: true,
          cache: true,
          title: '编辑司机档案'
        }
      }
    ]
  },
  {
    path: '/finance',
    component: layoutHeaderAside,
    children: [
      {
        path: '/finance/settlemanage/edit/index',
        name: 'settlemanage_edit',
        component: _import(`finance/settlemanage/edit/index.vue`),
        meta: {
          auth: true,
          cache: true,
          title: '编辑结算单'
        }
      }
    ]
  },
  {
    path: '/market',
    component: layoutHeaderAside,
    children: [
      {
        path: '/market/research/researchEdit/index',
        name: 'researchEdit',
        component: _import(`market/research/researchEdit/index.vue`),
        meta: {
          auth: true,
          cache: true,
          title: '编辑调研报告'
        }
      }
    ]
  },
  {
    path: '/financeform',
    component: layoutHeaderAside,
    children: [
      {
        path: '/financeform/deptIncomeExpenses/details',
        name: 'deptIncomeExpenses_details',
        component: _import(`financeform/deptIncomeExpenses/details.vue`),
        meta: {
          auth: true,
          cache: true,
          title: '部门收支明细'
        }
      }
    ]
  },
  {
    path: '/market',
    component: layoutHeaderAside,
    children: [
      {
        path: '/market/salesRevenue/edit/index',
        name: 'salesRevenue_edit',
        component: _import(`market/salesRevenue/edit/index.vue`),
        meta: {
          auth: true,
          cache: true,
          title: '编辑销售收入分配表'
        }
      }
    ]
  },
  {
    path: '/financeform',
    component: layoutHeaderAside,
    children: [
      {
        path: '/financeform/incomeBusiness/secondSubject/index',
        name: 'secondSubject',
        component: _import(`financeform/incomeBusiness/secondSubject/index.vue`),
        meta: {
          auth: true,
          cache: true,
          title: '直接费用二级科目'
        }
      }
    ]
  },
  {
    path: '/market',
    component: layoutHeaderAside,
    children: [
      {
        path: '/market/subproject/edit/index',
        name: 'subproject_edit',
        component: _import(`market/subproject/edit/index.vue`),
        meta: {
          auth: true,
          cache: true,
          title: '编辑子项目'
        }
      }
    ]
  },
  {
    path: '/market',
    component: layoutHeaderAside,
    children: [
      {
        path: '/market/subProjectCost/edit/index',
        name: 'subProjectCost_edit',
        component: _import(`market/subProjectCost/edit/index.vue`),
        meta: {
          auth: true,
          cache: true,
          title: '编辑子项目成本单'
        }
      }
    ]
  },
  {
    path: '/market',
    component: layoutHeaderAside,
    children: [
      {
        path: '/market/subProjectQuotation/edit/index',
        name: 'subProjectQuotation_edit',
        component: _import(`market/subProjectQuotation/edit/index.vue`),
        meta: {
          auth: true,
          cache: true,
          title: '编辑子项目报价单'
        }
      }
    ]
  },
  {
    path: '/market',
    component: layoutHeaderAside,
    children: [
      {
        path: '/market/inquirysheet/edit/index',
        name: 'inquirySheet_edit',
        component: _import(`market/inquirysheet/edit/index.vue`),
        meta: {
          auth: true,
          cache: true,
          title: '编辑询价单'
        }
      }
    ]
  },
  {
    path: '/market',
    component: layoutHeaderAside,
    children: [
      {
        path: '/market/inquirysheet/quote/index',
        name: 'inquirySheet_quote',
        component: _import(`market/inquirysheet/quote/index.vue`),
        meta: {
          auth: true,
          cache: true,
          title: '询价单报价'
        }
      }
    ]
  },
  {
    path: '/market',
    component: layoutHeaderAside,
    children: [
      {
        path: '/market/commission/edit/index',
        name: 'commissionEdit',
        component: _import(`market/commission/edit/index.vue`),
        meta: {
          auth: true,
          cache: true,
          title: '提成分配评分'
        }
      }
    ]
  },
  {
    path: '/basic',
    component: layoutHeaderAside,
    children: [
      {
        path: '/basic/dispatch/components/index',
        name: 'projectAllocation',
        component: _import(`basic/dispatch/components/index.vue`),
        meta: {
          auth: true,
          cache: true,
          title: '调度分配项目表'
        }
      }
    ]
  },
  {
    path: '/costcenterform',
    component: layoutHeaderAside,
    children: [
      {
        path: '/costcenterform/lineFreightRate/trend/index',
        name: 'lineFreightTrend',
        component: _import(`costcenterform/lineFreightRate/trend/index.vue`),
        meta: {
          auth: true,
          cache: true,
          title: '趋势图表'
        }
      }
    ]
  },
  {
    path: '/costcenterform',
    component: layoutHeaderAside,
    children: [
      {
        path: '/costcenterform/lineFreightRate/times/index',
        name: 'lineFreightTimes',
        component: _import(`costcenterform/lineFreightRate/times/index.vue`),
        meta: {
          auth: true,
          cache: true,
          title: '趟数明细'
        }
      }
    ]
  },
  {
    path: '/costcenterform',
    component: layoutHeaderAside,
    children: [
      {
        path: '/costcenterform/lineFreightRate/times/components/index',
        name: 'lineFreightInfoFee',
        component: _import(`costcenterform/lineFreightRate/times/components/index.vue`),
        meta: {
          auth: true,
          cache: true,
          title: '单个信息费'
        }
      }
    ]
  },
  {
    path: '/costcenterform',
    component: layoutHeaderAside,
    children: [
      {
        path: '/costcenterform/shuntingReport/project/index',
        name: 'shuntingReport_project',
        component: _import(`costcenterform/shuntingReport/project/index.vue`),
        meta: {
          auth: true,
          cache: true,
          title: '项目信息费'
        }
      }
    ]
  },
  {
    path: '/costcenterform',
    component: layoutHeaderAside,
    children: [
      {
        path: '/costcenterform/shuntingReport/personnel/index',
        name: 'shuntingReport_personnel',
        component: _import(`costcenterform/shuntingReport/personnel/index.vue`),
        meta: {
          auth: true,
          cache: true,
          title: '人员信息费'
        }
      }
    ]
  },
  {
    path: '/financeform',
    component: layoutHeaderAside,
    children: [
      {
        path: '/financeform/yearlyBudget/edit/index',
        name: 'yearlyBudget_edit',
        component: _import(`financeform/yearlyBudget/edit/index.vue`),
        meta: {
          auth: true,
          cache: true,
          title: '编辑年度预算'
        }
      }
    ]
  },
  {
    path: '/financeform',
    component: layoutHeaderAside,
    children: [
      {
        path: '/financeform/monthlyBudget/edit/index',
        name: 'monthlyBudget_edit',
        component: _import(`financeform/monthlyBudget/edit/index.vue`),
        meta: {
          auth: true,
          cache: true,
          title: '编辑月度预算'
        }
      }
    ]
  },
  {
    path: '/inventory',
    component: layoutHeaderAside,
    children: [
      {
        path: '/inventory/storage/edit/index',
        name: 'storage_edit',
        component: _import(`inventory/storage/edit/index.vue`),
        meta: {
          auth: true,
          cache: true,
          title: '编辑入库单'
        }
      }
    ]
  },
  {
    path: '/market',
    component: layoutHeaderAside,
    children: [
      {
        path: '/market/bondaccount/edit/index',
        name: 'bondAccount_edit',
        component: _import(`market/bondaccount/edit/index.vue`),
        meta: {
          auth: true,
          cache: true,
          title: '编辑保证金台账'
        }
      }
    ]
  },
  {
    path: '/wealth',
    component: layoutHeaderAside,
    children: [
      {
        path: '/wealth/payable/edit/index',
        name: 'payable_edit',
        component: _import(`wealth/payable/edit/index.vue`),
        meta: {
          auth: true,
          cache: true,
          title: '编辑应付账单'
        }
      }
    ]
  },
  {
    path: '/market',
    component: layoutHeaderAside,
    children: [
      {
        path: '/market/archives/edit/index',
        name: 'archivesEdit',
        component: _import(`market/archives/edit/index.vue`),
        meta: {
          auth: true,
          cache: true,
          title: '编辑客户档案'
        }
      }
    ]
  },
  {
    path: '/reportformOperate',
    component: layoutHeaderAside,
    children: [
      {
        path: '/reportformOperate/regionalOperations/edit/index',
        name: 'regionalOperations_edit',
        component: _import(`reportformOperate/regionalOperations/edit/index.vue`),
        meta: {
          auth: true,
          cache: true,
          title: '编辑大区经营日清'
        }
      }
    ]
  },
  {
    path: '/market',
    component: layoutHeaderAside,
    children: [
      {
        path: '/market/inquiryprojectbid/edit/index',
        name: 'inquiryProjectBid_edit',
        component: _import(`market/inquiryprojectbid/edit/index.vue`),
        meta: {
          auth: true,
          cache: true,
          title: '编辑项目标询价'
        }
      }
    ]
  },
  {
    path: '/market',
    component: layoutHeaderAside,
    children: [
      {
        path: '/market/inquiryprojectbid/quote/index',
        name: 'inquiryProjectBid_quote',
        component: _import(`market/inquiryprojectbid/quote/index.vue`),
        meta: {
          auth: true,
          cache: true,
          title: '项目标询价报价'
        }
      }
    ]
  },
  {
    path: '/collectionFund',
    component: layoutHeaderAside,
    children: [
      {
        path: '/budget/collectionFund/edit/index',
        name: 'collectionFund_edit',
        component: _import(`budget/collectionFund/edit/index.vue`),
        meta: {
          auth: true,
          cache: true,
          title: '编辑回款资金预算'
        }
      }
    ]
  },
  {
    path: '/operation',
    component: layoutHeaderAside,
    children: [
      {
        path: '/operation/union/edit/index',
        name: 'union_edit',
        component: _import(`operation/union/edit/index.vue`),
        meta: {
          auth: true,
          cache: true,
          title: '编辑联合单据'
        }
      }
    ]
  },
  {
    path: '/operation',
    component: layoutHeaderAside,
    children: [
      {
        path: '/operation/union/edit/components/index',
        name: 'unionCost_edit',
        component: _import(`operation/union/edit/components/index.vue`),
        meta: {
          auth: true,
          cache: true,
          title: '成本单据详情'
        }
      }
    ]
  },
  {
    path: '/financeform',
    component: layoutHeaderAside,
    children: [
      {
        path: '/financeform/customerList/edit/index',
        name: 'customerList_edit',
        component: _import(`financeform/customerList/edit/index.vue`),
        meta: {
          auth: true,
          cache: true,
          title: '编辑客户明细表'
        }
      }
    ]
  },
  {
    path: '/market',
    component: layoutHeaderAside,
    children: [
      {
        path: '/market/inquiryCost/edit/index',
        name: 'inquiryCost_edit',
        component: _import(`market/inquiryCost/edit/index.vue`),
        meta: {
          auth: true,
          cache: true,
          title: '编辑询价成本'
        }
      }
    ]
  },
  {
    path: '/market',
    component: layoutHeaderAside,
    children: [
      {
        path: '/market/bidQuotation/edit/index',
        name: 'bidQuotation_edit',
        component: _import(`market/bidQuotation/edit/index.vue`),
        meta: {
          auth: true,
          cache: true,
          title: '编辑投标报价'
        }
      }
    ]
  },
  {
    path: '/costcenterform',
    component: layoutHeaderAside,
    children: [
      {
        path: '/costcenterform/approvedDifference/cost/index',
        name: 'approvedDifference_cost',
        component: _import(`costcenterform/approvedDifference/cost/index.vue`),
        meta: {
          auth: true,
          cache: true,
          title: '核准差异成本报表'
        }
      }
    ]
  },
  {
    path: '/costcenterform',
    component: layoutHeaderAside,
    children: [
      {
        path: '/costcenterform/approvedDifference/income/index',
        name: 'approvedDifference_income',
        component: _import(`costcenterform/approvedDifference/income/index.vue`),
        meta: {
          auth: true,
          cache: true,
          title: '核准差异收入报表'
        }
      }
    ]
  },
  {
    path: '/customer',
    component: layoutHeaderAside,
    children: [
      {
        path: '/customer/tracking/edit/index',
        name: 'tracking_edit',
        component: _import(`customer/tracking/edit/index.vue`),
        meta: {
          auth: true,
          cache: true,
          title: '在途跟踪明细'
        }
      }
    ]
  },
  {
    path: '/market',
    component: layoutHeaderAside,
    children: [
      {
        path: '/market/biddingProfitLoss/edit/index',
        name: 'bidProfitLossEdit',
        component: _import(`market/biddingProfitLoss/edit/index.vue`),
        meta: {
          auth: true,
          cache: true,
          title: '编辑投标损益'
        }
      }
    ]
  }
]

/**
 * 错误页面
 */
const errorPage = [
  {
    path: '*',
    name: '404',
    component: _import('system/error/404')
  }
]

// 导出需要显示菜单的
export const frameInRoutes = frameIn
export const frameOutRoutes = frameOut
export const frameErrorRoutes = errorPage
export const frameEditRoutes = addEdit

// 重新组织后导出
export default [
  ...frameIn,
  ...frameOut,
  ...addEdit
]
