<template>
  <el-collapse v-model="activeNames">
    <el-collapse-item title="导入文件" name="1">
      <el-card shadow="hover">
        <el-upload
          class="import-demo"
          ref="upload"
          :headers="headers"
          :action="url"
          :data="importData"
          :on-preview="handlePreview"
          :on-remove="handleRemove"
          :before-remove="beforeRemove"
          :auto-upload="false"
          accept=".xls,.xlsx"
          :before-upload="beforeAvatarUpload"
          :limit="limitUpload"
          :on-error="handleError"
          :on-success="handleSuccess"
          :on-exceed="handleExceed"
          :on-change="handleChange"
          :file-list="fileList">
          <el-button slot="trigger" size="mini" type="primary">选择文件</el-button>
          <el-button style="margin-left: 10px;" size="mini" type="success" @click="submitUpload" :loading="loading">开始导入</el-button>
          <el-button v-if="this.importType === 'importPrice'"  size="mini" type="info" @click="priceDownload">下载模板</el-button>
          <el-button v-else-if="this.importType === 'yydImport'"  size="mini" type="info" @click="yydDownload">下载模板</el-button>
          <el-button v-else-if="this.importType === 'tydImport'"  size="mini" type="info" @click="tydDownload">下载模板</el-button>
          <el-button v-else-if="this.importType === 'yddImport'"  size="mini" type="info" @click="yddDownload">下载模板</el-button>
          <el-button v-else-if="this.importType === 'importLogisticVehicle'"  size="mini" type="info" @click="vehicleDownload">下载模板</el-button>
          <el-button v-else-if="this.importType === 'zcPriceImport'"  size="mini" type="info" @click="zcPriceDownload">下载模板</el-button>
          <el-button v-else-if="this.importType === 'ldPriceImport'"  size="mini" type="info" @click="ldPriceDownload">下载模板</el-button>
          <div slot="tip" class="el-upload__tip">只能上传xlsx/xls文件</div>
        </el-upload>
      </el-card>
    </el-collapse-item>
    <el-collapse-item title="导入结果" name="2">
      <div style="display: flex;flex-direction: column;align-items: end;">
        <el-button style="width: 80px; margin-bottom: 5px;" type="warning" plain round size="mini" @click="exportResultEvent">导出数据</el-button>
        <d2-data-edit ref="resultData" ModularName='importResults' :table_style="tableStyle" :Data="resultData" :width="55" :minWidth="55" :loading="loading" :isSelection="false" :isIndex="false" :isPage="false">
        </d2-data-edit>
      </div>
<!--      <el-table ref="resultData" :data="resultData" stripe border show-overflow-tooltip="true" height="300px">-->
<!--        <el-table-column header-align="center" align="center" prop="number" label="序号" width="80"-->
<!--                         :show-overflow-tooltip="true"></el-table-column>-->
<!--        <el-table-column header-align="center" align="center" prop="status" label="状态" width="100"-->
<!--                         :show-overflow-tooltip="true">-->
<!--          <template slot-scope="slot">-->
<!--            <el-tag type="danger" size="mini" v-if="slot.row.status === 0">失败</el-tag>-->
<!--            <el-tag type="success" size="mini" v-if="slot.row.status === 1">成功</el-tag>-->
<!--          </template>-->
<!--        </el-table-column>-->
<!--        <el-table-column header-align="center" align="center" prop="content" label="结果" width=""-->
<!--                         :show-overflow-tooltip="true"></el-table-column>-->
<!--        <el-table-column header-align="center" align="center" prop="docNo" label="单号" width=""-->
<!--                         :show-overflow-tooltip="true"></el-table-column>-->
<!--      </el-table>-->
      <el-row style="margin-top: 20px;">
        <el-col :span="6">
          <el-tag size="mini" type="">总条数：</el-tag><span style="margin-left: 5px;">{{formData.totalSize}}</span>
        </el-col>
        <el-col :span="6">
          <el-tag size="mini" type="">导入条数：</el-tag><span style="margin-left: 5px;">{{formData.importSize}}</span>
        </el-col>
        <el-col :span="6">
          <el-tag size="mini" type="success">成功条数：</el-tag><span style="margin-left: 5px;">{{formData.sessceSize}}</span>
        </el-col>
        <el-col :span="6">
          <el-tag size="mini" type="danger">失败条数：</el-tag><span style="margin-left: 5px;">{{formData.errorSize}}</span>
        </el-col>
      </el-row>
    </el-collapse-item>
  </el-collapse>
</template>
<script>

import store from '@/store/index'
import { returnImport } from '@/api/basic/customer'
import { returnImportPrice } from '@/api/basic/logistic'
import { returnImportPriceCus } from '@/api/basic/customerfreightrate' // 客户运价表 导入返回值
import { validatenull } from '@/tdcommon/validate'
import { excelExportFile } from '@/tdcommon/ExcelExport'

export default {
  name: 'd2-container-import',
  data () {
    return {
      loading: false,
      fileList: [],
      limitUpload: 1,
      activeNames: ['1', '2'],
      formData: [],
      resultData: [],
      fileMsg: {},
      importData: { id: this.importId },
      headers: {
        'Authorization': 'Bearer ' + store.getters.access_token,
        'TENANT-ID': 1
      },
      markone: 0,
      marktwo: 0,
      tableStyle: 'width: 100%;height: 300px'
    }
  },
  props: {
    // percentage: {
    //   type: Number,
    //   default: () => 0
    // },
    importVisible: {
      type: Boolean,
      default: () => false
    },
    disabled: {
      type: Boolean,
      default: () => false
    },
    multiple: {
      type: Boolean,
      default: () => false
    },
    url: {
      type: String,
      default: () => ''
    },
    importId: {
      type: Number,
      default: () => 0
    },
    importType: {
      type: String,
      default: () => ''
    }
  },
  methods: {
    handleChange (file, fileList) {
      this.fileList = fileList
    },
    // 关闭时清空srclist
    clearSrcList () {
      this.resultData = []
      this.$set(this.formData, 'totalSize', 0)
      this.$set(this.formData, 'importSize', 0)
      this.$set(this.formData, 'sessceSize', 0)
      this.$set(this.formData, 'errorSize', 0)
      this.$refs.upload.clearFiles() // 清空上传文件列表
    },
    // 下载零担运价模板
    priceDownload () {
      window.location.href = 'https://firs.oss-cn-shanghai.aliyuncs.com/excel/%E6%89%BF%E8%BF%90%E5%95%86%E4%BB%B7%E6%A0%BC%E6%A8%A1%E7%89%88.xlsx'
    },
    // 下载营运单模板
    yydDownload () {
      // window.location.href = 'https://firs.oss-cn-shanghai.aliyuncs.com/excel/%E8%90%A5%E8%BF%90%E5%8D%95%E6%A8%A1%E6%9D%BF4-15.xlsx'
      window.location.href = 'https://firs.oss-cn-shanghai.aliyuncs.com/excel/%E8%90%A5%E8%BF%90%E5%8D%95%E6%A8%A1%E6%9D%BF-240531.xlsx'
    },
    // 下载托运单模板
    tydDownload () {
      window.location.href = 'https://firs.oss-cn-shanghai.aliyuncs.com/excel/%E6%89%98%E8%BF%90%E5%8D%95%E6%A8%A1%E6%9D%BF4-15.xlsx'
    },
    // 下载预订单模板
    yddDownload () {
      window.location.href = 'https://firs.oss-cn-shanghai.aliyuncs.com/excel/%E9%A2%84%E8%AE%A2%E5%8D%95%E6%A8%A1%E6%9D%BF4-15.xlsx'
    },
    // 下载整车运价模板
    vehicleDownload () {
      window.location.href = 'https://firs.oss-cn-shanghai.aliyuncs.com/excel/%E6%95%B4%E8%BD%A6%E8%BF%90%E4%BB%B7%E8%A1%A8%E6%A8%A1%E6%9D%BF.xlsx'
    },
    // 下载整车价格导入模板
    zcPriceDownload () {
      window.location.href = 'https://firs.oss-cn-shanghai.aliyuncs.com/excel/%E9%A1%B9%E7%9B%AE%E8%BF%90%E4%BB%B7%E5%AF%BC%E5%85%A5%28%E6%95%B4%E8%BD%A6%29.xlsx'
    },
    // 下载零担价格导入模板
    ldPriceDownload () {
      window.location.href = 'https://firs.oss-cn-shanghai.aliyuncs.com/excel/%E9%A1%B9%E7%9B%AE%E8%BF%90%E4%BB%B7%E5%AF%BC%E5%85%A5%28%E9%9B%B6%E6%8B%85%29.xlsx'
    },
    // 上传
    submitUpload (importType) {
      this.loading = true
      if (!validatenull(this.fileList)) { // fileMsg
        this.$refs.upload.submit()
        this.timer = window.setInterval(this.alertFunc, 1000) // 循环返回值
      } else {
        this.$message.error('请选择导入文件！')
        this.loading = false
      }
    },
    handleError (response) {
      this.loading = false
      // return this.$confirm('导入失败！msg：' + response.msg)
      return this.$alert('msg：' + response.msg, '导入失败', { confirmButtonText: '确定' })
    },
    handleSuccess (response, file, fileList) {
      if (response.code !== 0) {
        // this.$message.error(response.msg)
        this.loading = false
        this.fileMsg = {} // 清空this.fileMsg
        window.clearInterval(this.timer) // 结束循环
        this.$refs.upload.clearFiles() // 清空上传文件列表
        return this.$alert(response.msg)
      }
    },
    alertFunc () {
      if (this.importType === 'importPrice' || this.importType === 'importLogisticVehicle') { // 如果是导入运价体系
        if (this.markone === this.marktwo) {
          this.markone += 1
          returnImportPrice().then(response => {
            this.marktwo += 1
            this.formData = response.data
            this.resultData = response.data.tydList
            if (response.data.importSize === response.data.totalSize && response.data.importSize !== 0) { // 如果导入条数等于总条数、导入条数不为0
              this.fileMsg = {} // 清空this.fileMsg
              window.clearInterval(this.timer) // 结束循环
              this.$emit('refreshMethod')
              this.$refs.upload.clearFiles() // 清空上传文件列表
              this.loading = false
            }
          }).catch(response => {
            this.fileMsg = {} // 清空this.fileMsg
            window.clearInterval(this.timer) // 结束循环
            this.$emit('refreshMethod')
            this.$refs.upload.clearFiles() // 清空上传文件列表
            this.loading = false
          })
        }
      } else if (this.importType === 'zcPriceImport' || this.importType === 'ldPriceImport') {
        if (this.markone === this.marktwo) {
          this.markone += 1
          returnImportPriceCus().then(response => {
            this.marktwo += 1
            this.formData = response.data
            this.resultData = response.data.tydList
            if (response.data.importSize === response.data.totalSize && response.data.importSize !== 0) { // 如果导入条数等于总条数、导入条数不为0
              this.fileMsg = {} // 清空this.fileMsg
              window.clearInterval(this.timer) // 结束循环
              this.$refs.upload.clearFiles() // 清空上传文件列表
              this.loading = false
            }
          }).catch(response => {
            this.fileMsg = {} // 清空this.fileMsg
            window.clearInterval(this.timer) // 结束循环
            this.$refs.upload.clearFiles() // 清空上传文件列表
            this.loading = false
          })
        }
      } else {
        if (this.markone === this.marktwo) {
          this.markone += 1
          returnImport().then(response => {
            this.marktwo += 1
            this.formData = response.data
            this.resultData = response.data.tydList
            if (response.data.importSize === response.data.totalSize && response.data.importSize !== 0) { // 如果导入条数等于总条数、导入条数不为0
              this.fileMsg = {} // 清空this.fileMsg
              window.clearInterval(this.timer) // 结束循环
              this.$refs.upload.clearFiles() // 清空上传文件列表
              this.loading = false
            }
          }).catch(response => {
            this.fileMsg = {} // 清空this.fileMsg
            window.clearInterval(this.timer) // 结束循环
            this.$refs.upload.clearFiles() // 清空上传文件列表
            this.loading = false
          })
        }
      }
    },
    handleRemove (file, fileList) {
      this.fileList = fileList
    },
    handlePreview (file, fileList) {
    },
    handleExceed (files, fileList) {
      this.$message.warning(`当前限制选择 1 个文件，共选择了 ${files.length + fileList.length} 个文件`)
    },
    beforeRemove (file, fileList) {
      return this.$confirm(`确定移除 ${file.name}？`)
    },
    // 判断导入的格式是不是xls、xlsx，如果不是则提示
    beforeAvatarUpload (file, fileList) {
      var testmsg = file.name.substring(file.name.lastIndexOf('.') + 1)
      const extension = testmsg === 'xls'
      const extension2 = testmsg === 'xlsx'
      // const isLt2M = file.size / 1024 / 1024 < 2
      if (!extension && !extension2) {
        this.$message.error('导入文件只支持 xls 或者 xlsx 格式！')
        return false
      }
      // if (!isLt2M) {
      //   this.$message.error('上传头像图片大小不能超过 2MB!')
      // }
      // return (extension || extension2) && isLt2M
      return extension || extension2
    },
    handleSelect (val) {
      this.$emit('change', val)
    },
    // 导出 结果数据
    exportResultEvent () {
      // excelExportResult(this.$refs.resultData.mtableColumns, this.resultData, '导入结果')
      excelExportFile(this.$refs.resultData.mtableColumns, this.resultData, '导入结果')
    }
  },
  watch: {
    importId (newVal, oldVal) {
      if (newVal) {
        this.importData = { id: newVal }
      }
    }
  },
  mounted () {
  }
}
</script>

<style lang="scss">
</style>

<vue-filename-injector>
export default function (Component) {
  Component.options.__source = "src/components/d2-container-import/index.vue"
}
</vue-filename-injector>
